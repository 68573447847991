.quest-membership {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  font-size: smaller;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr.author {
    color: var(--fgColor-default);
    background-color: var(--bgColor-accent-muted);
  }

  table,
  tbody,
  th,
  td {
    border-width: 1px;
    border-style: solid;
    border-spacing: 0;
    border-color: var(--borderColor-muted, var(--color-border-muted, hsla(210, 18%, 87%, 1)));
  }

  th {
    text-align: left;
    padding: 0.5rem 1rem;
  }

  td {
    padding: 0.5rem 1rem;
  }
}
.subitem {
  font-size: smaller;
  > th {
    padding-left: 1.5rem;
  }
}
